import { FormAnswersWithContactOutput, FormOutput } from "@addventa/sesha-forms-api";
import { CheckCircleFilled, HistoryOutlined } from "@ant-design/icons";
import { Button, Flex, Progress, Tooltip, theme } from "antd";
import dayjs from "dayjs";
import { EventSourcePolyfill } from "event-source-polyfill";
import { useEffect, useState } from "react";
import { apiFormAnswers } from "../../../../../api-configuration/Configuration";
import { useNotification } from "../../../../../hooks/useNotification";
import { manyFormsAnswersToCsv } from "../../../../../utils/formAnswersCSV";
import { downloadManyFormAnswers } from "../../../../../utils/formAnswersPdf";
import { ExtendedSeshaFormsAnswers } from "../../ReviewFormModel.d";

interface Props {
  form: FormOutput;
  setForm: React.Dispatch<React.SetStateAction<FormOutput | undefined>>;
  formAnswersList: ExtendedSeshaFormsAnswers[];
  setFormAnswersList: React.Dispatch<React.SetStateAction<ExtendedSeshaFormsAnswers[]>>;
  setModalMailReminderIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function YourFormAnswersStats(props: Props) {
  const [reminderSendDate, setReminderSendDate] = useState<Date | undefined>();
  const [downloadAllIsLoading, setDownloadAllIsLoading] = useState<boolean>(false);
  const [disableReminder, setDisableReminder] = useState<boolean>(false);
  const [exportCsvIsLoading, setExportCsvIsLoading] = useState<boolean>(false);
  const { showNotification } = useNotification();

  const {
    token: { colorPrimary, colorTextTertiary },
  } = theme.useToken();

  useEffect(() => {
    setReminderSendDate(
      props.formAnswersList.find((el) => el.reminderSendDate && el.reminderSendDate >= new Date())
        ?.reminderSendDate || undefined
    );
  }, [props.formAnswersList]);

  useEffect(() => {
    let events: EventSource;

    if (props.form.isGeneratingPDF) {
      const token = localStorage.getItem("token");

      events = new EventSourcePolyfill(
        `${process.env.REACT_APP_FORMS_BACKEND}/formanswers/pdfs/sse`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      events.addEventListener("manyPDFsGenerated", (event) => {
        if (event.data !== props.form._id) return;
        const updatedForm = { ...props.form };
        updatedForm.isGeneratingPDF = false;
        props.setForm(updatedForm);
        events.close();
      });
    }

    return () => {
      if (events) {
        events.close();
      }
    };
  }, [props.form.isGeneratingPDF]);

  useEffect(() => {
    (async () => {
      const allFormAnswers: FormAnswersWithContactOutput[] =
        await apiFormAnswers.formAnswersGetFormAnswersByFormId(props.form._id);

      if (allFormAnswers.length !== props.formAnswersList.length) setDisableReminder(true);
    })();
  }, [props.formAnswersList, props.form._id]);

  const getOpenRate = () => {
    if (props.formAnswersList.length === 0) return 0;
    const numberOfFormSent = props.formAnswersList.length;
    const numberOfOpen = props.formAnswersList.filter((data) => data.lastAccessDate).length;
    const openRate = (numberOfOpen / numberOfFormSent) * 100;
    return Math.round(openRate);
  };

  const getAverageCompletionRate = () => {
    if (props.formAnswersList.length === 0) return 0;
    const listOfCompletionRate = props.formAnswersList.map((data) => data.completionRate || 0);
    const sumOfCompletionRate = listOfCompletionRate.reduce((a, b) => a + b, 0);
    const completionRate = sumOfCompletionRate / listOfCompletionRate.length;
    return Math.round(completionRate);
  };

  const getNumberOfFormCompleted = () => {
    if (props.formAnswersList.length === 0) return 0;
    return props.formAnswersList.filter((data) => data.completionRate === 100).length;
  };

  const cancelSchedulingReminder = async () => {
    const updatedFormAnswersList: ExtendedSeshaFormsAnswers[] = props.formAnswersList
      .filter((el) => el.completionRate !== 100 && el.closed !== true)
      .map((el) => {
        const deletedDate = new Date(0);
        return { ...el, reminderSendDate: deletedDate };
      });

    const res = await apiFormAnswers.formAnswersUpdateMany(updatedFormAnswersList);
    props.setFormAnswersList((previous) => {
      return previous.map((el) => {
        if (el.reminderSendDate) {
          const deletedDate = new Date(0);
          return { ...el, reminderSendDate: deletedDate };
        }
        return el;
      });
    });

    if (!res.length) {
      showNotification(
        "error",
        "Erreur lors de l'annulation de la relance, veuillez réessayer ultérieurement ou nous contacter."
      );
    }
  };

  return (
    <Flex className="yourFormAnswers-cards" gap="large" wrap="wrap">
      <Flex vertical gap="middle">
        <Flex vertical align="center" style={{ height: "50%" }}>
          <h2>
            {props.formAnswersList.length}{" "}
            {props.formAnswersList.length > 1 ? "contacts" : "contact"}
          </h2>
          <h3>Nombre d'envois</h3>
        </Flex>
        <Flex vertical align="center" style={{ height: "50%" }}>
          <h2>{getOpenRate()}%</h2>
          <h3>Taux d'ouverture</h3>
        </Flex>
      </Flex>
      <Flex vertical gap="small">
        <Flex vertical align="center" gap="small" style={{ height: "100%" }}>
          <Progress
            type="circle"
            percent={getAverageCompletionRate()}
            size={[140, 140]}
            strokeColor={colorPrimary}
            strokeWidth={12}
            format={(percent) => percent + "%"}
            className="yourFormAnswerStats-progress-circle"
          />
          <h3>Taux de complétion moyen</h3>
        </Flex>
      </Flex>
      <Flex vertical gap="middle">
        <Flex style={{ alignItems: "center", justifyContent: "center" }} gap="small">
          <CheckCircleFilled style={{ fontSize: "40px", color: colorPrimary }} />
          <h1>{getNumberOfFormCompleted()}</h1>
          <h3 style={{ lineHeight: 1 }}>
            {getNumberOfFormCompleted() ? "formulaires complétés" : "formulaire complété"}
          </h3>
        </Flex>
        <span>
          <Flex vertical gap="middle">
            <Tooltip
              placement="top"
              title={
                props.form.isGeneratingPDF ? (
                  <div className="reviewForm-tooltip">
                    Vous pourrez télécharger les réponses une fois la génération terminée.
                  </div>
                ) : (
                  ""
                )
              }
            >
              <Button
                style={
                  props.form.isGeneratingPDF
                    ? { marginTop: "10px" }
                    : { marginTop: "10px", width: "100%", minWidth: "250px" }
                }
                type="primary"
                loading={props.form.isGeneratingPDF || downloadAllIsLoading}
                onClick={async () => {
                  setDownloadAllIsLoading(true);
                  await downloadManyFormAnswers(
                    props.form,
                    props.formAnswersList.map((fa) => fa.contact)
                  );
                  setDownloadAllIsLoading(false);
                }}
                disabled={props.form.isGeneratingPDF}
              >
                Télécharger tous les formulaires
              </Button>
            </Tooltip>
            <Button
              style={exportCsvIsLoading ? {} : { width: "100%", minWidth: "250px" }}
              loading={exportCsvIsLoading}
              onClick={async () => {
                setExportCsvIsLoading(true);
                await manyFormsAnswersToCsv(
                  props.form,
                  props.formAnswersList.map((fa) => fa.contact)
                );
                setExportCsvIsLoading(false);
              }}
              disabled={exportCsvIsLoading}
            >
              Exporter les réponses (csv)
            </Button>
            <Flex align="center" justify="center" gap="large">
              <Tooltip
                title={
                  disableReminder
                    ? "Il est nécessaire d'avoir accès à l'intégralité des contacts pour programmer une relance"
                    : ""
                }
              >
                <Button
                  className="client-icon"
                  shape="circle"
                  style={{
                    color: colorPrimary,
                  }}
                  onClick={() => props.setModalMailReminderIsOpen(true)}
                  icon={<HistoryOutlined style={{ position: "relative", top: 1, fontSize: 20 }} />}
                  disabled={disableReminder}
                />{" "}
              </Tooltip>
              <p style={{ color: colorTextTertiary, margin: 0 }}>
                {reminderSendDate && reminderSendDate > new Date() ? (
                  <Flex vertical>
                    <p>Relance programmée le {dayjs(reminderSendDate).format("DD/MM/YYYY")}</p>
                    <a onClick={() => cancelSchedulingReminder()} style={{ width: "fit-content" }}>
                      Annuler
                    </a>
                  </Flex>
                ) : (
                  "Pas de relance programmée"
                )}
              </p>
            </Flex>
          </Flex>
        </span>
      </Flex>
    </Flex>
  );
}
