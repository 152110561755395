import { FormAnswersOutput, FormOutput } from "@addventa/sesha-forms-api";
import { CopyOutlined, FileAddOutlined, FileTextOutlined } from "@ant-design/icons";
import type { TabsProps } from "antd";
import { Button, Col, ConfigProvider, Modal, Row, Select, Tabs, theme } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { apiForm, apiFormAnswers } from "../../api-configuration/Configuration";
import { EFeatures } from "../../enums/EFeatures";
import { usePermission } from "../../hooks/usePermission";
import { SeshaOption } from "../../types/SeshaForm/SeshaOption";
import { Contents } from "../../types/misc/Generic";
import "./Homepage.css";
import ContactList from "./tabs/ContactList";
import FormList from "./tabs/FormList";

function Homepage(props: {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  filteredContactsIds: string[];
  reloadSearch: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    token: { colorPrimaryBgHover, colorPrimary },
  } = theme.useToken();

  const [activeTab, setActiveTab] = useState<string>("forms");
  const [isCreateFormModalOpen, setIsCreateFormModalOpen] = useState<boolean>(false);
  const [forms, setForms] = useState<FormOutput[]>([]);
  const [formsAnswers, setFormsAnswers] = useState<FormAnswersOutput[]>([]);
  const [options, setOptions] = useState<Contents<SeshaOption>[]>([]);
  const [optionsTemplates] = useState<Contents<SeshaOption>[]>([
    { value: "2042", label: "Déclaration de revenus - Mise à jour de vos informations" },
    { value: "new_employee", label: "Nouveau salarié" },
    { value: "financial_year_end", label: "Finalisation de l'exercice" },
  ]);
  const [choice, setChoice] = useState<"new" | "duplicate" | "template" | undefined>();
  const [formIdDuplicate, setFormIdDuplicate] = useState<string>("");
  const [formIdTemplate, setFormIdTemplate] = useState<string>("");
  const [formsLoading, setFormsLoading] = useState<boolean>(true);
  const canCreateForm = usePermission(EFeatures.CREATE_AND_SEND_FORM);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const activeTabFromUrl = params.get("tab");

    if (activeTabFromUrl && ["forms", "contacts"].includes(activeTabFromUrl)) {
      window.history.replaceState({}, "", "/");
      setActiveTab(activeTabFromUrl);
      props.setTab(activeTabFromUrl);
    } else {
      setActiveTab("forms");
      props.setTab("forms");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    (async () => {
      const allForms: FormOutput[] = await apiForm.formFindAll();
      setForms(allForms.map((form: FormOutput) => ({ ...form, key: form._id })));
      setFormsLoading(false);
      setOptions(
        allForms
          .filter((form) => form.draft === false)
          .map((form: FormOutput) => ({ value: form._id, label: form.title }))
      );
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const allFormAnswers: FormAnswersOutput[] = await apiFormAnswers.formAnswersFindAll();
      setFormsAnswers(allFormAnswers);
    })();
  }, []);

  const items: TabsProps["items"] = [
    {
      key: "forms",
      label: "Formulaires",
      children: <FormList forms={forms} formsAnswers={formsAnswers} loading={formsLoading} />,
      forceRender: true,
    },
    {
      key: "contacts",
      label: "Contacts",
      children: (
        <ContactList
          forms={forms}
          filteredContactsIds={props.filteredContactsIds}
          reloadSearch={(data) => props.reloadSearch(data)}
        />
      ),
      forceRender: true,
    },
  ];

  const handleCancel = () => {
    setIsCreateFormModalOpen(false);
    setChoice(undefined);
  };

  const handleChangeDuplicate = (value: string) => {
    setFormIdDuplicate(value);
  };

  const handleChangeTemplate = (value: string) => {
    setFormIdTemplate(value);
  };

  const redirectFormCreation = () => {
    if (choice === "new") {
      navigate("/form/create");
    } else if (choice === "duplicate" || choice === "template") {
      const state = { [choice]: choice === "duplicate" ? formIdDuplicate : formIdTemplate };
      navigate("/form/create", { state });
    }
  };

  return (
    <Row justify="center" className="homepage">
      <Col xs={24} sm={22} md={22} lg={22}>
        <Tabs
          activeKey={activeTab}
          items={items}
          tabBarExtraContent={
            (activeTab !== "forms" || canCreateForm) && (
              <Button
                type="primary"
                onClick={() =>
                  activeTab === "forms" ? setIsCreateFormModalOpen(true) : navigate("/contacts")
                }
              >
                {activeTab === "forms" ? "Créer un formulaire" : "Ajouter des contacts"}
              </Button>
            )
          }
          onChange={(key) => {
            setActiveTab(key);
            props.setTab(key);
          }}
          indicator={{ size: 35, align: "start" }}
        />
      </Col>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              headerBg: colorPrimaryBgHover,
            },
          },
        }}
      >
        <Modal
          title="Création d'un formulaire"
          open={isCreateFormModalOpen}
          onCancel={handleCancel}
          footer={null}
          data-cy="create-form-modal"
        >
          <Row
            style={{
              marginBottom: "10px",
              backgroundColor: choice === "new" ? colorPrimaryBgHover : "",
              boxShadow:
                choice === "new"
                  ? "inset 0px 3px 15px rgba(0, 0, 0, 0.2)"
                  : "0px 3px 15px rgba(0, 0, 0, 0.2)",
            }}
            className="create-modal-btn"
            onClick={() => setChoice("new")}
          >
            <Col span={4}>
              <FileAddOutlined style={{ color: colorPrimary, fontSize: "60px" }} />
            </Col>
            <Col span={20}>
              <h3>De A à Z</h3>
              <p>Créez votre formulaire de A à Z sans partir d’un modèle.</p>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "10px",
              backgroundColor: choice === "duplicate" ? colorPrimaryBgHover : "",
              boxShadow:
                choice === "duplicate"
                  ? "inset 0px 3px 15px rgba(0, 0, 0, 0.2)"
                  : "0px 3px 15px rgba(0, 0, 0, 0.2)",
            }}
            className="create-modal-btn"
            onClick={() => setChoice("duplicate")}
          >
            <Col span={4}>
              <CopyOutlined style={{ color: colorPrimary, fontSize: "60px" }} />
            </Col>
            <Col span={20}>
              <h3>à partir d'un formulaire existant</h3>
              <p>Dupliquez un formulaire existant et personnalisez-le à votre guise.</p>
              <Select
                style={{ width: "100%" }}
                placeholder="Veuillez sélectionner un formulaire existant"
                onChange={handleChangeDuplicate}
                options={options}
                optionFilterProp="label"
                showSearch={true}
              />
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "10px",
              backgroundColor: choice === "template" ? colorPrimaryBgHover : "",
              boxShadow:
                choice === "template"
                  ? "inset 0px 3px 15px rgba(0, 0, 0, 0.2)"
                  : "0px 3px 15px rgba(0, 0, 0, 0.2)",
            }}
            className="create-modal-btn"
            onClick={() => setChoice("template")}
          >
            <Col span={4}>
              <FileTextOutlined style={{ color: colorPrimary, fontSize: "60px" }} />
            </Col>
            <Col span={20}>
              <h3>à partir d'un modèle prédéfini</h3>
              <p>Utilisez un des modèles proposés et personnalisez-le.</p>
              <Select
                style={{ width: "100%" }}
                placeholder="Veuillez sélectionner votre modèle"
                onChange={handleChangeTemplate}
                options={optionsTemplates}
                optionFilterProp="label"
                showSearch={true}
              />
            </Col>
          </Row>
          <div style={{ textAlign: "right" }}>
            <Button
              type="primary"
              style={{ marginTop: 10, right: 0 }}
              onClick={() => redirectFormCreation()}
              disabled={
                !choice ||
                (choice !== "new" && choice === "duplicate" && formIdDuplicate === "") ||
                (choice !== "new" && choice === "template" && formIdTemplate === "")
              }
            >
              Valider
            </Button>
          </div>
        </Modal>
      </ConfigProvider>
    </Row>
  );
}

export default Homepage;
