import {
  AccountingFirmOutput,
  ContactInput,
  UserContactInput,
  UserOutput,
} from "@addventa/sesha-forms-api";
import {
  CloseOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  TableOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  Button,
  ConfigProvider,
  Divider,
  Dropdown,
  Flex,
  Form,
  Input,
  Select,
  Space,
  Table,
  Tag,
  theme,
} from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useNotification } from "../../../../hooks/useNotification";

import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  apiAccountingFirm,
  apiContact,
  apiTag,
  apiUser,
  apiUserContactAsso,
} from "../../../../api-configuration/Configuration";
import Context from "../../../../context/Context";
import { EContactCreationStatus, ExtendedSeshaContact } from "../../ContactModel";
import { ContactCreationExcelModal } from "./ContactCreationExcelModal";
import ContactCreationSuccess from "./ContactCreationSuccess";
import { EditableCell, EditableRow } from "./ContactCreationTableComponents";

const colors = [
  "#FFDCFB", // pink
  "#DCFFE4", // green
  "#DDDCFF", // violet
  "#FFEDDC", // brown
  "#DCF7FF", // blue
  "#FFDCDC", // red
];

function ContactCreation() {
  const {
    token: { colorError, colorPrimaryBgHover, colorPrimary, colorTextSecondary, colorTextTertiary },
  } = theme.useToken();
  const { user } = useContext(Context);
  const { showNotification } = useNotification();
  const [contactCreationStatus, setContactCreationStatus] = useState<EContactCreationStatus>(
    EContactCreationStatus.IDLE
  );
  const [tableDataSource, setTableDataSource] = useState<ExtendedSeshaContact[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [tags, setTags] = useState<{ label: string; value: string; color: string }[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [originalColors, setOriginalColors] = useState("#FFDCFB");
  const [users, setUsers] = useState<UserOutput[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [accountingFirm, setAccountingFirm] = useState<AccountingFirmOutput>();

  const navigate = useNavigate();

  const handleDelete = (key: Number) => {
    let newData: ExtendedSeshaContact[] = tableDataSource.filter((item) => item.key !== key);
    if (!newData[0].isFirst) {
      newData[0].isFirst = true;
    }
    newData.forEach((_, index) => (newData[index].key = index));
    setTableDataSource(newData);
  };

  const handleSave = (row: ExtendedSeshaContact) => {
    setTableDataSource((prevDataSource) => {
      row["isNewlyAdded"] = false;
      const index = prevDataSource.findIndex((item) => row.key === item.key);
      let newData = [...prevDataSource];
      newData[index] = { ...newData[index], ...row };
      newData[index].isInErrorState = false;
      return newData;
    });
  };

  const handleAdd = () => {
    const newData = {
      _id: "",
      accountingFirmId: "fake accounting id",
      creatorId: "creator-id",
      createdAt: new Date(),
      key: tableDataSource.length,
      firstName: "",
      lastName: "",
      email: "",
      mailToBox: "",
      iDepot: "",
      isNewlyAdded: true,
    };
    setTableDataSource([...tableDataSource, newData]);
  };

  useEffect(() => {
    (async () => {
      setUsers(await apiUser.userFindAll());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const resTags = await apiTag.tagFindAll();
      const alltags = [];
      for (const tag of resTags) {
        alltags.push({ label: tag.label, value: tag._id, color: tag.color });
      }
      setTags(alltags);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const resAcc = await apiAccountingFirm.accountingfirmFindOne();
      setAccountingFirm(resAcc);
    })();
  }, []);

  useEffect(() => {
    const contactTemplate: ExtendedSeshaContact = {
      accountingFirmId: "fake accounting id",
      creatorId: "creator-id",
      createdAt: new Date(),
      key: 0,
      firstName: "",
      lastName: "",
      email: "",
      mailToBox: "",
      iDepot: "",
      isNewlyAdded: true,
      isFirst: true,
    };
    setTableDataSource([contactTemplate]);
  }, []);

  const defaultColumns = [
    {
      title: "Prénom",
      dataIndex: "firstName",
      key: "firstName",
      editable: true,
      width: "20%",
    },
    {
      title: "Nom",
      dataIndex: "lastName",
      key: "lastName",
      editable: true,
      width: "20%",
      sorter: false,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "25%",
      editable: true,
      sorter: false,
    },
    {
      title: "Mail to box (facultatif)",
      dataIndex: "mailToBox",
      key: "mailToBox",
      width: "20%",
      editable: true,
    },
    {
      title: "Code dossier (facultatif)",
      dataIndex: "iDepot",
      key: "iDepot",
      width: "21%",
      editable: true,
      hidden:
        !accountingFirm ||
        (accountingFirm && (accountingFirm.dataProvider !== "ACD" || accountingFirm.fec)),
    },
    {
      title: "",
      dataIndex: "operation",
      width: "5%",
      render: (_: null, record: ExtendedSeshaContact) =>
        !record.isFirst || tableDataSource.length > 1 ? (
          <a onClick={() => handleDelete(record.key)} style={{ color: colorTextSecondary }}>
            <CloseOutlined />
          </a>
        ) : null,
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;
    const color = tags?.find((tag) => tag.label === label)?.color;

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          marginLeft: 3,
          marginBottom: 1,
          borderRadius: 8,
          color: "#2F2F2F",
        }}
      >
        {label}
      </Tag>
    );
  };

  const items: ItemType[] = [
    {
      key: "1",
      style: {
        height: "50px",
      },
      label: (
        <Flex gap={10}>
          <UserAddOutlined style={{ fontSize: "24px", color: colorPrimary }} />
          <p>Ajouter un contact</p>
        </Flex>
      ),
      onClick: () => handleAdd(),
    },
    {
      key: "2",
      style: {
        height: "50px",
      },
      onClick: () => setModalIsOpen(true),
      label: (
        <Flex gap={10}>
          <TableOutlined style={{ fontSize: "24px", color: colorPrimary }} />
          <p>Importer un fichier XLS</p>
        </Flex>
      ),
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ExtendedSeshaContact) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  const selectTags = (value: string[]) => {
    setSelectedTags(value);
  };

  const onFinish = async () => {
    let hasEmptyInput = EContactCreationStatus.IDLE;
    for (const contact of tableDataSource) {
      if (!contact.firstName || !contact.lastName || !contact.email) {
        contact.isInErrorState = true;
        hasEmptyInput = EContactCreationStatus.ERROR;
        continue;
      }
    }
    setTableDataSource([...tableDataSource]);
    if (hasEmptyInput === EContactCreationStatus.ERROR) {
      setContactCreationStatus(EContactCreationStatus.ERROR);
      return;
    }
    setContactCreationStatus(EContactCreationStatus.LOADING);

    const tableDataSourceToContacts: ContactInput[] = tableDataSource.map((contact) => ({
      accountingFirmId: contact.accountingFirmId,
      createdAt: contact.createdAt,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      mailToBox: contact.mailToBox,
      iDepot: contact.iDepot,
      tags: selectedTags,
    }));

    try {
      const allContacts = await apiContact.contactFindAll();

      const emailLIst = allContacts.map((contact) => contact.email);
      const emailCounts = tableDataSource.reduce((counts: any, contact) => {
        if (contact.email) {
          counts[contact.email] = (counts[contact.email] || 0) + 1;

          if (emailLIst.includes(contact.email)) {
            counts[contact.email] = (counts[contact.email] || 0) + 1;
          }
        }
        return counts;
      }, {});

      const duplicateEmails = Object.keys(emailCounts).filter((email) => emailCounts[email] > 1);
      const contactsWithDuplicatedEmail = tableDataSource.filter((contact) =>
        duplicateEmails.includes(contact.email!)
      );

      if (duplicateEmails.length > 0) {
        const tableDataSourceToContactsWithErrors = contactsWithDuplicatedEmail.map((contact) => {
          if (duplicateEmails.includes(contact.email!)) {
            contact.emailIsDuplicate = true;
          }
          return contact;
        });
        for (const contact of tableDataSourceToContactsWithErrors) {
          handleSave(contact);
        }

        setContactCreationStatus(EContactCreationStatus.ERROR);
        throw new Error("Email déjà attribué à un contact");
      }

      const response = await apiContact.contactCreateManyRaw({
        contactInput: tableDataSourceToContacts,
      });
      const resJson = await response.raw.json();
      if (response.raw.status === 208) {
        showNotification("error", "Email déjà attribué à un contact");
      } else {
        if (resJson.length === tableDataSource.length) {
          const asso: UserContactInput[] = resJson
            .map((contact: any) =>
              selectedUsers.map((selectedUser) => ({
                userId: selectedUser,
                contactId: contact._id,
              }))
            )
            .flat(1);
          apiUserContactAsso.userContactAssoCreateMany(asso);
          setContactCreationStatus(EContactCreationStatus.SUCCESS);
        } else {
          setContactCreationStatus(EContactCreationStatus.ERROR);
        }
      }
    } catch (error: any) {
      showNotification("error", error.message);
      setContactCreationStatus(EContactCreationStatus.IDLE);
    }
  };

  const onAddTag = (values: any) => {
    (async () => {
      const resTag = await apiTag.tagCreateOne({ label: values.label, color: originalColors });

      setTags([...tags, { label: values.label, color: originalColors, value: resTag._id }]);
    })();
  };

  return contactCreationStatus === EContactCreationStatus.SUCCESS ? (
    <ContactCreationSuccess
      setContactCreationStatus={setContactCreationStatus}
      setTableDataSource={setTableDataSource}
      setTags={setTags}
    />
  ) : (
    <Flex vertical gap="middle" className="contactCreation">
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: colorPrimaryBgHover,
            },
            Select: {
              borderRadius: 5,
            },
            Input: {
              borderRadius: 10,
            },
          },
        }}
      >
        <Table
          components={components}
          columns={columns}
          dataSource={tableDataSource}
          pagination={false}
        />

        {tableDataSource.length === 1 && tableDataSource[0].isNewlyAdded === true && (
          <>
            <p style={{ color: colorTextTertiary, textAlign: "end", margin: 0 }}>
              Appuyez sur "entrée" pour ajouter le contact.
            </p>
            <Flex vertical justify="center" align="center" style={{ margin: 20 }}>
              <UserAddOutlined style={{ color: colorTextTertiary, fontSize: 60 }} />
              <h3
                style={{
                  color: colorTextTertiary,
                  maxWidth: 470,
                  textAlign: "center",
                }}
              >
                Ajouter des contacts manuellement en remplissant le tableau ou importer un fichier
                XLS.
              </h3>
              <Button type="primary" onClick={() => setModalIsOpen(true)}>
                Importer un fichier XLS
              </Button>
            </Flex>
          </>
        )}

        {tableDataSource.length >= 1 && !tableDataSource[0].isNewlyAdded && (
          <Dropdown menu={{ items }} placement="bottomLeft" trigger={["click"]}>
            <PlusCircleOutlined
              style={{ fontSize: "30px", color: colorPrimary }}
              className="plus-circle-icon"
            />
          </Dropdown>
        )}
        {accountingFirm && (
          <ContactCreationExcelModal
            setTableDataSource={setTableDataSource}
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
            dataProvider={accountingFirm.dataProvider}
            fec={accountingFirm.fec}
          />
        )}
        <>
          <Flex gap="large">
            <Flex align="center" justify="start" gap="large" style={{ width: "20%" }}>
              <p>Ajouter des tags</p>
            </Flex>{" "}
            <Flex align="center" justify="start" gap="large" style={{ width: "80%" }}>
              <Select
                mode="multiple"
                tagRender={tagRender}
                allowClear
                options={tags}
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
                onChange={selectTags}
                style={{ width: "100%" }}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <Space style={{ padding: "0 8px 4px" }}>
                      <Form layout="inline" onFinish={onAddTag}>
                        <Form.Item
                          name="label"
                          rules={[{ required: true, message: "Merci de renseigner le nom du tag" }]}
                        >
                          <Input
                            style={{ width: "200px", borderRadius: "20px" }}
                            placeholder="Ajouter un tag"
                          />
                        </Form.Item>
                        <span
                          style={{
                            marginRight: "5px",
                            marginTop: "5px",
                            verticalAlign: "middle",
                          }}
                        >
                          Couleur :{" "}
                        </span>
                        {colors.map((color) => {
                          return (
                            <Button
                              shape="circle"
                              key={color}
                              style={{
                                border: `3px solid ${
                                  originalColors === color ? colorPrimary : "transparent"
                                }`,
                                backgroundColor: color,
                                marginRight: "5px",
                                marginTop: "5px",
                              }}
                              size="small"
                              onClick={(e) => {
                                e.preventDefault();
                                setOriginalColors(color);
                              }}
                            ></Button>
                          );
                        })}
                        <Form.Item style={{ textAlign: "right", marginLeft: "20px" }}>
                          <Button type="primary" htmlType="submit">
                            Ajouter
                          </Button>
                        </Form.Item>
                      </Form>
                    </Space>
                  </>
                )}
              />
            </Flex>
          </Flex>
          <Flex gap="large">
            <Flex align="center" justify="start" gap="large" style={{ width: "20%" }}>
              <p>Gérer les accès</p>
            </Flex>
            <Flex align="center" justify="start" gap="large" style={{ width: "80%" }}>
              {users.length && (
                <>
                  <div style={{ width: "125%" }}>
                    <Select
                      mode="multiple"
                      value={selectedUsers}
                      options={users
                        .filter((u) => u._id !== user?._id)
                        .map((u) => ({
                          value: u._id,
                          label: u.firstName + " " + u.lastName,
                        }))}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={(value) => setSelectedUsers(value)}
                      allowClear
                      defaultValue={users.filter((u) => u._id !== user?._id).map((u) => u._id)}
                      maxTagCount="responsive"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <Button
                      type="link"
                      onClick={() =>
                        setSelectedUsers(users.filter((u) => u._id !== user?._id).map((u) => u._id))
                      }
                    >
                      Tout sélectionner
                    </Button>
                    /
                    <Button type="link" onClick={() => setSelectedUsers([])}>
                      Tout désélectionner
                    </Button>
                  </div>
                </>
              )}
            </Flex>
          </Flex>
          <Flex justify="end" gap="middle">
            <Button type="default" onClick={() => navigate("/?tab=contacts")}>
              Retour
            </Button>
            <Button
              type="primary"
              disabled={tableDataSource.length === 1 && tableDataSource[0].isNewlyAdded}
              onClick={onFinish}
              loading={contactCreationStatus === EContactCreationStatus.LOADING}
            >
              Ajouter {tableDataSource.length} contact{tableDataSource.length >= 2 && "s"}
            </Button>
          </Flex>
        </>
        {contactCreationStatus === EContactCreationStatus.ERROR && (
          <Flex gap="small" align="center" justify="end">
            <ExclamationCircleOutlined style={{ color: colorError }} />
            <p style={{ transform: "translateY(1px)", color: colorError, margin: 0 }}>
              Renseignez tous les champs obligatoires pour continuer.
            </p>
          </Flex>
        )}
      </ConfigProvider>
    </Flex>
  );
}

export default ContactCreation;
